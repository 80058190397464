import React from "react";
import Styles from "./HomeStyles";
import { api } from "../../services";
import { CustomText, Footer, FooterWebApp } from "../../genericComponents";
import { Colors, Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";
import NoticeBox from "./components/NoticeBox";
import { hooks, SessionStorage } from "../../utils";
import { useNavigate } from "react-router-dom";
import ReportBox from "./components/ReportBox";
import GraphHome from "./components/GraphHome";
import images from "../../config/images";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import '../../config/home.css';

import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import Icon from '@material-ui/core/Icon';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Modal from '@material-ui/core/Modal';

import { paths } from "../../navigation/navigate";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { CircularProgress} from "@material-ui/core";
import { Chart } from "react-google-charts";


import './HomeWebApp.css';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  cabecaAcordeao: {
    backgroundColor: Colors.azulEcom,
    color: Colors.white
  },
  tituloAcordeao: {
    color: Colors.white
  },
  corpoAcordeao: {
    marginTop: Spacing(3)
  },
  labelFiltro: {
    color: Colors.black
  },
  MenuItem: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      color: Colors.white
    },
    '&$selected': {
      color: Colors.white
    }
  },
  paper: {
    position: 'absolute',
    width: '40rem',
    maxWidth: '100%',
    maxHeight: '95%',
    overflow: 'auto',
  },
  paper2: {
    width: '94%',
    maxWidth: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'white',
    borderRadius: '23px',
    maxHeight: '100%',
    marginLeft: '23px',
    textAlign: 'center',
    fontSize: '18px'

  }
}));

const status = [
  "Indicação Enviada",
  "Cadastro Realizado",
  "Indicação Reprovada"
];

const statusnegocio = [
  "N/A",
  "Em negociação",
  "Negócio fechado",
];

const names = [
  "São Paulo"
];

const UF = [
  {"nome": "Acre", "sigla": "AC"},
  {"nome": "Alagoas", "sigla": "AL"},
  {"nome": "Amapá", "sigla": "AP"},
  {"nome": "Amazonas", "sigla": "AM"},
  {"nome": "Bahia", "sigla": "BA"},
  {"nome": "Ceará", "sigla": "CE"},
  {"nome": "Distrito Federal", "sigla": "DF"},
  {"nome": "Espírito Santo", "sigla": "ES"},
  {"nome": "Goiás", "sigla": "GO"},
  {"nome": "Maranhão", "sigla": "MA"},
  {"nome": "Mato Grosso", "sigla": "MT"},
  {"nome": "Mato Grosso do Sul", "sigla": "MS"},
  {"nome": "Minas Gerais", "sigla": "MG"},
  {"nome": "Pará", "sigla": "PA"},
  {"nome": "Paraíba", "sigla": "PB"},
  {"nome": "Paraná", "sigla": "PR"},
  {"nome": "Pernambuco", "sigla": "PE"},
  {"nome": "Piauí", "sigla": "PI"},
  {"nome": "Rio de Janeiro", "sigla": "RJ"},
  {"nome": "Rio Grande do Norte", "sigla": "RN"},
  {"nome": "Rio Grande do Sul", "sigla": "RS"},
  {"nome": "Rondônia", "sigla": "RO"},
  {"nome": "Roraima", "sigla": "RR"},
  {"nome": "Santa Catarina", "sigla": "SC"},
  {"nome": "São Paulo", "sigla": "SP"},
  {"nome": "Sergipe", "sigla": "SE"},
  {"nome": "Tocantins", "sigla": "TO"}
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function HomeWebApp() {
  const classes = useStyles();
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  const [bulletins, setBulletins] = React.useState(null);
  const [listReports, setReports] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [openAtention, setOpenAtention] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  var viewport_width = document.documentElement.clientWidth;
  const userPerfil = SessionStorage.getItem("userPerfil");
  
  const userEmail = SessionStorage.getItem("email");
  // const { setPathPageView, setIdentity } = useTrackingCode();
  // setIdentity(userEmail);
  // setPathPageView("/dashboard");

  const [modalStyle] = React.useState(getModalStyle);

  const handleNotice = (id) => {
    if (id === open) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };
  const handleCloseAtention = () => {
    setOpenAtention(false);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };
  const handleChangeUF = (event) => {
    setEstado(event.target.value);
  };
  const handleChangeStatusNegocio = (event) => {
    setStatusNegocio(event.target.value);
  };
  const handleChangeStatusIndicacao = (event) => {
    setStatusIndicacao(event.target.value);
  };
  const handleCliente = (event) => {
    setCliente(event.target.value);
  };
  const handleRazaoSocial = (event) => {
    setRazaoSocial(event.target.value);
  };
  const handleResponsavel = (event) => {
    setResponsavel(event.target.value);
  };
  const handleMunicipio = (event) => {
    setMunicipio(event.target.value);
  };
  const handleCodigo_uc = (event) => {
    setCodigo_uc(event.target.value);
  };
  

  const [personName, setPersonName] = React.useState([]);
  const [estado, setEstado] = React.useState([]);
  const [status_indicacao, setStatusIndicacao] = React.useState([]);
  const [status_negocio, setStatusNegocio] = React.useState([]);
  const [cliente, setCliente] = React.useState([]);
  const [razao_social, setRazaoSocial] = React.useState([]);
  const [responsavel, setResponsavel] = React.useState([]);
  const [municipio, setMunicipio] = React.useState([]);
  const [codigo_uc, setCodigo_uc] = React.useState([]);

  const [vlrEmpresasCadastradas, setvlrEmpresasCadastradas] = React.useState(0);
  const [vlrIndicacoes, setvlrIndicacoes] = React.useState(0);
  const [vlrIndicacoesEmAndamento, setvlrIndicacoesEmAndamento] = React.useState(0);
  const [vlrClientesFechados, setvlrClientesFechados] = React.useState(0);
  const [vlrNegocioPerdido, setvlrNegocioPerdido] = React.useState(0);

  const [qtdEmpresasCadastradas, setqtdEmpresasCadastradas] = React.useState(0);
  const [qtdIndicacoes, setqtdIndicacoes] = React.useState([]);
  const [qtdIndicacoesValidadas, setqtdIndicacoesValidadas] = React.useState([]);
  const [qtdIndicacoesEmAndamento, setqtdIndicacoesEmAndamento] = React.useState([]);
  const [qtdClientesFechados, setqtdClientesFechados] = React.useState([]);
  const [qtdNegocioPerdido, setqtdNegocioPerdido] = React.useState([]);
  const [rows, setrows] = React.useState([]);
  const [abreAcordeao, setabreAcordeao] = React.useState([]);
  const [dadosParceiro, setdadosParceiro] = React.useState([]);
  const [carregandopagina, setcarregandopagina] = React.useState(false);
  const [carregandografico, setcarregandografico] = React.useState(true);
  const [documentosreprovados, setdocumentosreprovados] = React.useState(0);

  const handleUC = (event) => {
    setUC(event.target.value);
  };
  const [uc, setUC] = React.useState([]);
  
  const handleCNPJ = (event) => {
    setCNPJ(event.target.value);
  };
  const [cnpj, setCNPJ] = React.useState([]);
  
  const handleDataIndicacao = (event) => {
    setDataIndicacao(event.target.value);
  };
  const [dataIndicacao, setDataIndicacao] = React.useState([]);
  const user_id = SessionStorage.getItem("user_id");
  const avisoAssinatura = SessionStorage.getItem("avisoAssinatura");
  const [graficoEmpresasCadastradas, setgraficoEmpresasCadastradas] = React.useState(0);
  const [graficoIndicacoesRealizadas, setgraficoIndicacoesRealizadas] = React.useState(0);
  const [graficoEmNegociacao, setgraficoEmNegociacao] = React.useState(0);
  const [graficoClientesFechados, setgraficoClientesFechados] = React.useState(0);
  const [graficoNegocioPerdido, setgraficoNegocioPerdido] = React.useState(0);
  const [graficoPreCadastro, setgraficoPreCadastro] = React.useState(0);
  const [graficoCadastro, setgraficoCadastro] = React.useState(0);
  const [graficoAguardandoAssinatura, setgraficoAguardandoAssinatura] = React.useState(0);
  const [graficoAssinados, setgraficoAssinados] = React.useState(0);
  const [graficoPerdidos, setgraficoPerdidos] = React.useState(0);
  const [graficoValidacao, setgraficoValidacao] = React.useState(0);

  const [pieData, setPieData] = React.useState([
    ["Indicações", "Status"],
    ["Cadastrados", 1],
    ["Realizadas", 1],
    ["Em negociação", 1],
    ["Fechados", 1],
    ["Perdido", 1], // CSS-style declaration
  ]);

  const pieOptions = {
    legend: {
      position: 'bottom',
      horizontalAlignment: 'center',
      verticalAlignment: 'bottom',
      display: 'flex',
    },
    chartArea: {
      width: '90%',
      height: '80%'
    }
    // chartArea: {right: 15, left: 15},
    // pieHole: 0.3,
    // is3D: false,
  };
  
  const mount = React.useCallback(async () => {
    setcarregandopagina(true);

    setabreAcordeao(false);
    
    call(null, api.getDadosGraficoVendas(user_id), (response4) => {
      setcarregandopagina(true);
      setcarregandografico(true);

      if(response4.ok) {
        setcarregandopagina(false);
        if(response4.data){
          setgraficoPreCadastro(response4.data.pre_cadastro);
          setgraficoCadastro(response4.data.cadastro);
          setgraficoAguardandoAssinatura(response4.data.aguardando_assinatura);
          setgraficoAssinados(response4.data.assinados);
          setgraficoPerdidos(response4.data.perdidos);
          setgraficoValidacao(response4.data.validacao);
          setcarregandografico(false);

          const novaPieData = [
                ["Vendas", "Status"],
                ["Pré-cadastro", parseInt(response4.data.pre_cadastro)],
                ["Cadastro", parseInt(response4.data.cadastro)],
                ["Aguardando assinatura", parseInt(response4.data.aguardando_assinatura)],
                ["Assinados", parseInt(response4.data.assinados)],
                ["Perdidos", parseInt(response4.data.perdidos)],
                ["Validacao", parseInt(response4.data.validacao)],
              ];
          setPieData(novaPieData);
    
        } else {
          
        }
      }
    });
    
    call(null, api.dadosDoParceiro(user_id), (response5) => {
      setcarregandopagina(true);
      if(response5.ok) {
        setcarregandopagina(false);
        setdadosParceiro(response5.data);
        console.log('dados parceiro');
        console.log(user_id);
        console.log(response5.data);
      }
    });

    call(null, api.documentosReprovados(user_id), (responser) => {
      setcarregandopagina(true);
      if(responser.ok) {
        setcarregandopagina(false);
        if(responser.data.length > 0) {
          setdocumentosreprovados(responser.data.length);
          setrows(responser.data);
        }
        console.log('Documentos reprovados: '+responser.data.length);
      }
    });
    
    
  }, [call]);

  React.useEffect(() => {
    
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  
  const columns = [
    {id: 'responsavel',label: 'Contato',align: 'left'},
    {id: 'celular', label: 'Celular', align: 'left', },
    {id: 'UC',label: 'UC',align: 'left'},
    {id: 'tipo',label: 'Status',align: 'left'},
  ];
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const filtrarParceiros = () => {
    var busca = {"cliente":(cliente.length > 0 ? cliente : ''),"razao_social":(razao_social.length > 0 ? razao_social : ''),"responsavel":(responsavel.length > 0 ? responsavel : ''),"municipio":(municipio.length > 0 ? municipio : ''),"estado":(estado.length > 0 ? estado : ''),"codigo_uc":(codigo_uc.length > 0 ? codigo_uc : ''),"cnpj":(cnpj.length > 0 ? cnpj : ''),"status_indicacao":(status_indicacao.length > 0 ? status_indicacao : ''),"status_negocio":(status_negocio.length > 0 ? status_negocio : '')}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }
  
  
  const filtrarClientesFechados = () => {
    navigate(paths.indicacoesWebApp, {state: { status: "Cliente fechado"}});
  }
  
  const filtrarEmNegociacao = () => {
    navigate(paths.indicacoesWebApp, {state: { status: "Aguardando assinaturas"}});
  }
  
  const filtrarEmValidacao = () => {
    navigate(paths.indicacoesWebApp, {state: { status: "Validação"}});
  }
  
  const filtrarIndicacaoEnviada = () => {
    navigate(paths.indicacoesWebApp, {state: { status: "Cadastro completo"}});
  }
  
  const filtrarCadastroRealizado = () => {
    navigate(paths.indicacoesWebApp, {state: { status: "Pré cadastro"}});
  }
  
  const filtrarNegocioPerdido = () => {
    navigate(paths.indicacoesWebApp, {state: { status: "Negócio Perdido"}});
  }

  const limparFiltro = () => {
    setEstado('');
    setStatusIndicacao('');
    setStatusNegocio('');
    setCliente('');
    setRazaoSocial('');
    setResponsavel('');
    setMunicipio('');
    setCodigo_uc('');
  }
  
  
  return (
    <>
      <Styles.Container className="container-webpp">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} xl={12} md={12}>
              <div className="ola_parceiroecom">
                {dadosParceiro.apelido}
              </div>
              <hr className="hr"></hr>
            </Grid>
            
            <Grid item xs={12} sm={12} xl={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                className="botao-chamativo"
                onClick={() => {
                  navigate(paths.stepcadastroIndicacaoParceiroWebApp);
                }}
              >
                Iniciar venda
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} xl={12} md={12}>
              <div className="ola_busca">
                Busca
              </div>
              <hr className="hr"></hr>
            </Grid>
            <Grid item xs={12} sm={12} xl={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="razao_social"
                  value={responsavel}
                  onChange={handleResponsavel}
                  startAdornment={<InputAdornment position="start"></InputAdornment>}
                  labelWidth={60}
                  variant="outlined"
                  label="Digite o nome do responsável"
                  className="campo-fantasia"
                >
                </TextField>
                <Icons.Busca className="icone-busca" onClick={()=>{navigate(paths.indicacoesWebApp, {state: { responsavel: responsavel}})}}></Icons.Busca>
              </FormControl>
            </Grid>
              <Grid item xs={12} sm={12} xl={12} md={12}>
                <hr className="hr"></hr>
                <div className="ola_busca">
                  Dashboard
                  {
                    (carregandopagina == true) 
                    &&
                    <>
                    <CircularProgress 
                      className="carregando-botao-webapp"
                    />
                    </>
                  }
                </div>
              </Grid>

          </Grid>

          <Grid container justifyContent="center" spacing={4}>

          {
            (documentosreprovados > 0)
            &&
            <>
            <div className="ola_busca_reprovados">
                ATENÇÃO!!! VOCÊ POSSUI DOCUMENTOS REPROVADOS OU PENDENTES!
            </div>
            <Styles.CorpoTabela>
              <TableContainer 
              className="tabelao">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            switch (column.id) {
                              case 'responsavel':
                                var valor = row.responsavel;
                                break;
                              case 'celular':
                                var valor = row.celular;
                                break;
                              case 'UC':
                                var valor = row.codigo_uc;
                                break;
                              case 'tipo':
                                var valor = row.tipo;
                                break;
                            }
                            
                            return (
                              <TableCell 
                                className="cell-reprovados"
                                key={column.id} 
                                align={column.align}
                                onClick={()=>{navigate(paths.editarcadastroIndicacaoParceiroWebApp, {state: { cliente_id: row.cliente_id, unidade_consumidora_id: row.unidade_consumidora_id}})}}
                                >
                                {column.format && typeof value === 'number' ? column.format(valor) : valor}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              
            </Styles.CorpoTabela>
            </>
          }  
          <h1 className="h1-titulo">Acompanhe seus negócios</h1>
          {
            (carregandografico === false)
            ?
            <>
            <Grid item xs={12} sm={12} xl={12} md={12}>
              <Grid container justifyContent="center">
              <Grid item xs={3} sm={3} xl={3} md={3} className="gridWebApp">
                  <Paper 
                    variant="outlined"
                    onClick={filtrarCadastroRealizado}
                    className="empresascadastradaswebapp"

                  >
                    <Styles.CorpoPaper>
                      <div className="numeroswebapp">
                        {graficoPreCadastro}
                      </div>
                      <div className="legendagraficowebapp">
                        PRÉ<br/>
                        CADASTRO
                      </div>
                    </Styles.CorpoPaper>
                  </Paper>
                </Grid>
                <Grid item xs={3} sm={3} xl={3} md={3} className="gridWebApp">
                  <Paper 
                    variant="outlined"
                    onClick={filtrarIndicacaoEnviada}
                    className="indicacoesrealizadaswebapp"

                  >
                    <Styles.CorpoPaper>
                      <div className="numeroswebapp">
                        {graficoCadastro}
                      </div>
                      <div className="legendagraficowebapp">
                        CADASTROS<br/>
                        COMPLETOS
                      </div>
                    </Styles.CorpoPaper>
                  </Paper>
                </Grid>
                <Grid item xs={3} sm={3} xl={3} md={3} className="gridWebApp">
                  <Paper 
                    variant="outlined"
                    onClick={filtrarEmValidacao}
                    className="emvalidacaowebapp"

                  >
                    <Styles.CorpoPaper>
                      <div className="numeroswebapp">
                        {graficoValidacao}
                      </div>
                      <div className="legendagraficowebapp">
                        EM<br/>VALIDAÇÂO
                      </div>
                    </Styles.CorpoPaper>
                  </Paper>
                </Grid>
                
                
              </Grid>
              </Grid>            
              <Grid item xs={12} sm={12} xl={12} md={12}>
              <Grid container justifyContent="center">  
              <Grid item xs={3} sm={3} xl={3} md={3} className="gridWebApp">
                  <Paper 
                    variant="outlined"
                    onClick={filtrarEmNegociacao}
                    className="emnegociacaowebapp"

                  >
                    <Styles.CorpoPaper>
                      <div className="numeroswebapp">
                        {graficoAguardandoAssinatura}
                      </div>
                      <div className="legendagraficowebapp">
                        AGUARDANDO<br/>ASSINATURAS
                      </div>
                    </Styles.CorpoPaper>
                  </Paper>
                </Grid>  
              <Grid item xs={3} sm={3} xl={3} md={3} className="gridWebApp">
                  <Paper 
                    variant="outlined"
                    onClick={filtrarClientesFechados}
                    className="clientesfechadoswebapp"

                  >
                    <Styles.CorpoPaper>
                      <div className="numeroswebapp">
                        {graficoAssinados}
                      </div>
                      <div className="legendagraficowebapp">
                        CLIENTES<br/>FECHADOS
                      </div>
                    </Styles.CorpoPaper>
                  </Paper>
                </Grid>
                <Grid item xs={3} sm={3} xl={3} md={3} className="gridWebApp">
                  <Paper 
                    variant="outlined"
                    onClick={filtrarNegocioPerdido}
                    className="negocioPerdidowebapp"

                  >
                    <Styles.CorpoPaper>
                      <div className="numeroswebapp">
                        {graficoPerdidos}
                      </div>
                      <div className="legendagraficowebapp">
                        NEGÓCIO<br/>PERDIDO
                      </div>
                    </Styles.CorpoPaper>
                  </Paper>
                </Grid>
                
              </Grid>
            </Grid>
            

            <Chart
              chartType="PieChart"
              width="100%"
              height="290px"
              data={pieData}
              options={pieOptions}
            />
            
          
            </>
            :
            <>
            <div className="espacocima">
              <CircularProgress 
                className="carregando-botao-webapp"
              />  Carregando gráfico de vendas
            </div>
            </>
          }    
            
            
            
          </Grid>
          {/* <p><spam>() = Comissão esperada</spam></p> */}
          
          
            <Styles.CorpoTabela>
              <Grid container justifyContent="center" spacing={12}>
                {/* <Button
                  variant="contained"
                  color="primary"
                  className="botao-incluir"
                  endIcon={<GroupAddIcon />}
                  onClick={() => {
                    navigate(paths.cadastroIndicacaoParceiro);
                  }}
                >
                  Indicar uma nova empresa
                </Button> */}
              </Grid>

              

              
            </Styles.CorpoTabela>
        
            <Modal
              open={openAtention}
              onClose={handleCloseAtention}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              disableScrollLock={false}
              className="modalatention"
          >
          <>
            <div style={modalStyle} className={classes.paper}>
              <img src={images.iconeatencao} className="iconeatencao"  />
              <div className={classes.paper2}>
                <h3 className="h3atention">Assinaturas pendentes</h3>
                <p>Você tem assinaturas pendentes no acordo de parceria!</p>
                <Button 
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleCloseAtention();
                    }}
                    className="fecharatencao"
                >
                  Fechar
                </Button>
              </div>
            </div>
          </>
          </Modal>
        
        {/*  ----            Footer               ------------ */}
      </Styles.Container>
      <FooterWebApp />
    </>
  );
}