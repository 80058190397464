import React from "react";
import Styles from "./HomeStyles";
import { api } from "../../services";
import { CustomText, Footer } from "../../genericComponents";
import { Colors, Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";
import NoticeBox from "./components/NoticeBox";
import { hooks, SessionStorage } from "../../utils";
import { useNavigate } from "react-router-dom";
import ReportBox from "./components/ReportBox";
import GraphHome from "./components/GraphHome";
import images from "../../config/images";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import '../../config/home.css';

import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import Icon from '@material-ui/core/Icon';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Modal from '@material-ui/core/Modal';

import { paths } from "../../navigation/navigate";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import useDeviceDetection from '../../useDeviceDetection';


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  cabecaAcordeao: {
    backgroundColor: Colors.azulEcom,
    color: Colors.white
  },
  tituloAcordeao: {
    color: Colors.white
  },
  corpoAcordeao: {
    marginTop: Spacing(3)
  },
  labelFiltro: {
    color: Colors.black
  },
  MenuItem: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      color: Colors.white
    },
    '&$selected': {
      color: Colors.white
    }
  },
  paper: {
    position: 'absolute',
    width: '40rem',
    maxWidth: '100%',
    maxHeight: '95%',
    overflow: 'auto',
  },
  paper2: {
    width: '94%',
    maxWidth: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'white',
    borderRadius: '23px',
    maxHeight: '100%',
    marginLeft: '23px',
    textAlign: 'center',
    fontSize: '18px'

  }
}));

const status = [
  "Indicação Enviada",
  "Cadastro Realizado",
  "Indicação Reprovada"
];

const statusnegocio = [
  "N/A",
  "Em negociação",
  "Negócio fechado",
];

const names = [
  "São Paulo"
];

const UF = [
  {"nome": "Acre", "sigla": "AC"},
  {"nome": "Alagoas", "sigla": "AL"},
  {"nome": "Amapá", "sigla": "AP"},
  {"nome": "Amazonas", "sigla": "AM"},
  {"nome": "Bahia", "sigla": "BA"},
  {"nome": "Ceará", "sigla": "CE"},
  {"nome": "Distrito Federal", "sigla": "DF"},
  {"nome": "Espírito Santo", "sigla": "ES"},
  {"nome": "Goiás", "sigla": "GO"},
  {"nome": "Maranhão", "sigla": "MA"},
  {"nome": "Mato Grosso", "sigla": "MT"},
  {"nome": "Mato Grosso do Sul", "sigla": "MS"},
  {"nome": "Minas Gerais", "sigla": "MG"},
  {"nome": "Pará", "sigla": "PA"},
  {"nome": "Paraíba", "sigla": "PB"},
  {"nome": "Paraná", "sigla": "PR"},
  {"nome": "Pernambuco", "sigla": "PE"},
  {"nome": "Piauí", "sigla": "PI"},
  {"nome": "Rio de Janeiro", "sigla": "RJ"},
  {"nome": "Rio Grande do Norte", "sigla": "RN"},
  {"nome": "Rio Grande do Sul", "sigla": "RS"},
  {"nome": "Rondônia", "sigla": "RO"},
  {"nome": "Roraima", "sigla": "RR"},
  {"nome": "Santa Catarina", "sigla": "SC"},
  {"nome": "São Paulo", "sigla": "SP"},
  {"nome": "Sergipe", "sigla": "SE"},
  {"nome": "Tocantins", "sigla": "TO"}
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function Home() {
  const classes = useStyles();
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  const [bulletins, setBulletins] = React.useState(null);
  const [listReports, setReports] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [openAtention, setOpenAtention] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  var viewport_width = document.documentElement.clientWidth;
  const userPerfil = SessionStorage.getItem("userPerfil");
  
  const userEmail = SessionStorage.getItem("email");
  // const { setPathPageView, setIdentity } = useTrackingCode();
  // setIdentity(userEmail);
  // setPathPageView("/dashboard");

  const [modalStyle] = React.useState(getModalStyle);

  const handleNotice = (id) => {
    if (id === open) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };
  const handleCloseAtention = () => {
    setOpenAtention(false);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };
  const handleChangeUF = (event) => {
    setEstado(event.target.value);
  };
  const handleChangeStatusNegocio = (event) => {
    setStatusNegocio(event.target.value);
  };
  const handleChangeStatusIndicacao = (event) => {
    setStatusIndicacao(event.target.value);
  };
  const handleCliente = (event) => {
    setCliente(event.target.value);
  };
  const handleRazaoSocial = (event) => {
    setRazaoSocial(event.target.value);
  };
  const handleResponsavel = (event) => {
    setResponsavel(event.target.value);
  };
  const handleMunicipio = (event) => {
    setMunicipio(event.target.value);
  };
  const handleCodigo_uc = (event) => {
    setCodigo_uc(event.target.value);
  };
  

  const [personName, setPersonName] = React.useState([]);
  const [estado, setEstado] = React.useState([]);
  const [status_indicacao, setStatusIndicacao] = React.useState([]);
  const [status_negocio, setStatusNegocio] = React.useState([]);
  const [cliente, setCliente] = React.useState([]);
  const [razao_social, setRazaoSocial] = React.useState([]);
  const [responsavel, setResponsavel] = React.useState([]);
  const [municipio, setMunicipio] = React.useState([]);
  const [codigo_uc, setCodigo_uc] = React.useState([]);

  const [vlrEmpresasCadastradas, setvlrEmpresasCadastradas] = React.useState(0);
  const [vlrIndicacoes, setvlrIndicacoes] = React.useState(0);
  const [vlrIndicacoesEmAndamento, setvlrIndicacoesEmAndamento] = React.useState(0);
  const [vlrClientesFechados, setvlrClientesFechados] = React.useState(0);
  const [vlrNegocioPerdido, setvlrNegocioPerdido] = React.useState(0);

  const [graficoEmpresasCadastradas, setgraficoEmpresasCadastradas] = React.useState(0);
  const [graficoIndicacoesRealizadas, setgraficoIndicacoesRealizadas] = React.useState(0);
  const [graficoEmNegociacao, setgraficoEmNegociacao] = React.useState(0);
  const [graficoClientesFechados, setgraficoClientesFechados] = React.useState(0);
  const [graficoNegocioPerdido, setgraficoNegocioPerdido] = React.useState(0);

  const [qtdEmpresasCadastradas, setqtdEmpresasCadastradas] = React.useState(0);
  const [qtdIndicacoes, setqtdIndicacoes] = React.useState([]);
  const [qtdIndicacoesValidadas, setqtdIndicacoesValidadas] = React.useState([]);
  const [qtdIndicacoesEmAndamento, setqtdIndicacoesEmAndamento] = React.useState([]);
  const [qtdClientesFechados, setqtdClientesFechados] = React.useState([]);
  const [qtdNegocioPerdido, setqtdNegocioPerdido] = React.useState([]);
  const [rows, setrows] = React.useState([]);
  const [abreAcordeao, setabreAcordeao] = React.useState([]);
  const [dadosParceiro, setdadosParceiro] = React.useState([]);
  

  const handleUC = (event) => {
    setUC(event.target.value);
  };
  const [uc, setUC] = React.useState([]);
  
  const handleCNPJ = (event) => {
    setCNPJ(event.target.value);
  };
  const [cnpj, setCNPJ] = React.useState([]);
  
  const handleDataIndicacao = (event) => {
    setDataIndicacao(event.target.value);
  };
  const [dataIndicacao, setDataIndicacao] = React.useState([]);
  const user_id = SessionStorage.getItem("user_id");
  const avisoAssinatura = SessionStorage.getItem("avisoAssinatura");
  const device = useDeviceDetection();
  var viewport_width = document.documentElement.clientWidth;

  const pegaValores = () => {
    console.log("pega valores a cada 10 minutos");
    call(null, api.valoresIndicacoes(user_id), (response) => {
      if(response.ok) {
        setvlrEmpresasCadastradas(response.data.empresas_cadastradas);
        setvlrIndicacoes(response.data.indicacoes_realizadas);
        setvlrIndicacoesEmAndamento(response.data.em_negociacao);
        setvlrClientesFechados(response.data.clientes_fechados);
        setvlrNegocioPerdido(response.data.negocio_perdido);
      }
    });

  }

  const mount = React.useCallback(async () => {
    if(device == 'Mobile' || viewport_width < 601 || 1 == 1) {
      navigate(paths.homeWebApp);
    }

    if(SessionStorage.getItem("avisoAssinatura") == 'SIM') {
      call(null, api.statusAssinaturasPorUsuario(user_id), (response) => {
        if(response.ok) {
          if(response?.data) {
            console.log(response?.data);
          }
        }
      });
    }
    
    call(null, api.modalAssinatura(), (response) => {
      if(response.ok) {
        console.log(response.data);
        if(response.data === 'true') {
          call(null, api.verificaStatusAssinaturaParceiro(user_id), (response) => {
            if(response.ok) {
              if(response?.data != 'completed') {
                if(SessionStorage.getItem("avisoAssinatura") == 'SIM') {
                  setOpenAtention(true);
                  SessionStorage.setItem(
                    "avisoAssinatura",
                    'NAO'
                  );
                }
            }
          }
          });
        }
    }
    });

    call(null, api.listaEmpresas(user_id), (response) => {
      if(response.ok) {
        console.log(response.data);  
        setrows(response.data);
      }
    });    

    setabreAcordeao(false);
    

    call(null, api.getDadosDashboard(user_id), (response4) => {

      if(response4.ok) {
        if(response4.data){
          setqtdEmpresasCadastradas(response4.data.empresas_cadastradas);
          setqtdIndicacoes(response4.data.indicacoes_realizadas);
          setqtdIndicacoesEmAndamento(response4.data.em_negociacao);
          setqtdClientesFechados(response4.data.clientes_fechados);
          setqtdNegocioPerdido(response4.data.negocio_perdido);
        } else {
          
        }
      }
    });
    
    
    
    call(null, api.dadosDoParceiro(user_id), (response5) => {
      if(response5.ok) {
        setdadosParceiro(response5.data);
        console.log('dados parceiro');
        console.log(user_id);
        console.log(response5.data);
      }
    });

    pegaValores();
    
  }, [call]);

  React.useEffect(() => {
    
    if (!mounted) {
      mount();
      setMounted(true);
    }
    const interval = setInterval(() => {
        pegaValores()
    }, 600000);

    //Clearing the interval
    return () => clearInterval(interval);

  }, [mount, mounted]);

  
  const columns = [
    { id: 'cliente', label: 'Cliente', align: 'left', },
    {id: 'responsavel',label: 'Responsável',align: 'left'},
    {id: 'estado',label: 'UF',align: 'center'},
    {id: 'unidades',label: 'N de unidades',align: 'center'},
    {id: 'codigo_uc',label: 'Cód de UC',align: 'center'},
    {id: 'cnpj',label: 'CNPJ Unidade',align: 'center'},
    {id: 'data_indicacao',label: 'Data indicação',align: 'center'},
    {id: 'status_indicacao',label: 'Status da Indicação',align: 'center'},
    {id: 'status_negocio',label: 'Status Negócio',align: 'center'},
    {id: 'produto_escolhido',label: 'Produto selecionado',align: 'center'},
    {id: 'comissao_esperada',label: 'Comissão esperada (5 anos)',align: 'center'},
    // {id: 'data_expiracao',label: 'Data de expiração',align: 'center'},
  ];
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const filtrarParceiros = () => {
    var busca = {"cliente":(cliente.length > 0 ? cliente : ''),"razao_social":(razao_social.length > 0 ? razao_social : ''),"responsavel":(responsavel.length > 0 ? responsavel : ''),"municipio":(municipio.length > 0 ? municipio : ''),"estado":(estado.length > 0 ? estado : ''),"codigo_uc":(codigo_uc.length > 0 ? codigo_uc : ''),"cnpj":(cnpj.length > 0 ? cnpj : ''),"status_indicacao":(status_indicacao.length > 0 ? status_indicacao : ''),"status_negocio":(status_negocio.length > 0 ? status_negocio : '')}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }
  
  const filtrarNegocioPerdido = () => {
    var busca = {"cliente":"","razao_social":"","responsavel":"","municipio":"","estado":"","codigo_uc":"","cnpj":"","status_indicacao":"","status_negocio":"Negócio perdido"}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }
  
  const filtrarClientesFechados = () => {
    var busca = {"cliente":"","razao_social":"","responsavel":"","municipio":"","estado":"","codigo_uc":"","cnpj":"","status_indicacao":"","status_negocio":"Negócio fechado"}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }
  
  const filtrarEmNegociacao = () => {
    var busca = {"cliente":"","razao_social":"","responsavel":"","municipio":"","estado":"","codigo_uc":"","cnpj":"","status_indicacao":"","status_negocio":"Em negociação"}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }
  
  const filtrarIndicacaoEnviada = () => {
    var busca = {"cliente":"","razao_social":"","responsavel":"","municipio":"","estado":"","codigo_uc":"","cnpj":"","status_indicacao":"Indicação Enviada","status_negocio":""}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }
  
  const filtrarCadastroRealizado = () => {
    var busca = {"cliente":"","razao_social":"","responsavel":"","municipio":"","estado":"","codigo_uc":"","cnpj":"","status_indicacao":"Cadastro Realizado","status_negocio":""}
    console.log(busca);
    call(null, api.parceirosIndicacoes(user_id, busca), (response) => {
      if(response.ok) {
        setrows(response.data);
      }
    });
  }

  const limparFiltro = () => {
    setEstado('');
    setStatusIndicacao('');
    setStatusNegocio('');
    setCliente('');
    setRazaoSocial('');
    setResponsavel('');
    setMunicipio('');
    setCodigo_uc('');
  }
  
  
  return (

    <Styles.Container>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={12} xl={9} md={9}>
            <div className="ola_parceiro">
              Olá <b>{dadosParceiro.apelido}</b> , Seja muito bem vindo
            </div>
          </Grid>
          <Grid item xs={12} sm={12} xl={3} md={3}>
            <Button
              variant="contained"
              color="primary"
              className="botao-chamativo"
              onClick={() => {
                navigate(paths.fluxocadastroIndicacaoParceiro);
              }}
            >
              Cadastrar Indicação
            </Button>
          </Grid>
        </Grid>

        <h1>Acompanhe seus negócios</h1>
        <p>Veja abaixo o status dos negócios cadastrados e andamento do fluxo de prospecção dos mesmos.</p>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={12} xl={2} md={2}>
            <Paper 
              variant="outlined"
              onClick={filtrarCadastroRealizado}
              className="papelfinger"

            >
              <Styles.CorpoPaper>
                <Styles.DivNumeros>
                  {qtdEmpresasCadastradas}
                </Styles.DivNumeros>
                <Styles.Pbox>
                  Empresas<br/> Cadastradas<br />
                  (R$ {vlrEmpresasCadastradas})
                </Styles.Pbox>
              </Styles.CorpoPaper>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} xl={2} md={2}>
            <Paper 
              variant="outlined"
              className="papelfinger"
            >
              <Styles.CorpoPaper onClick={filtrarIndicacaoEnviada}>
                <Styles.DivNumeros>
                  {qtdIndicacoes}
                </Styles.DivNumeros>
                <Styles.Pbox>
                  Indicações<br/> Realizadas<br />
                  (R$ {vlrIndicacoes})
                </Styles.Pbox>
              </Styles.CorpoPaper>
            </Paper>  
          </Grid>    
          <Grid item xs={12} sm={12} xl={2} md={2}>
            <Paper 
              variant="outlined"
              className="papelfinger"
            >
              <Styles.CorpoPaper onClick={filtrarEmNegociacao}>
                <Styles.DivNumeros>
                  {qtdIndicacoesEmAndamento}
                </Styles.DivNumeros>
                <Styles.Pbox>
                  Em<br/> Negociação<br />
                  (R$ {vlrIndicacoesEmAndamento})
                </Styles.Pbox>
              </Styles.CorpoPaper>
              </Paper>
          </Grid>
          <Grid item xs={12} sm={12} xl={2} md={2}>
            <Paper 
              variant="outlined"
              onClick={filtrarClientesFechados}
              className="papelfinger"
            >
              <Styles.CorpoPaper>
                <Styles.DivNumeros>
                  {qtdClientesFechados}
                </Styles.DivNumeros>
                <Styles.Pbox>
                  Clientes<br/> Fechados<br />
                  (R$ {vlrClientesFechados})
                </Styles.Pbox>
              </Styles.CorpoPaper>
            </Paper>  
          </Grid>
          <Grid item xs={12} sm={12} xl={2} md={2}>
            <Paper 
              variant="outlined" 
              onClick={filtrarNegocioPerdido}
              className="papelfinger"
            >
              <Styles.CorpoPaper>
                <Styles.DivNumeros>
                  {qtdNegocioPerdido}
                </Styles.DivNumeros>
                <Styles.Pbox>
                  Negocio<br/> Perdido<br />
                  (R$ {vlrNegocioPerdido})
                </Styles.Pbox>
              </Styles.CorpoPaper>
            </Paper>  
          </Grid>
        </Grid>
        <p><spam>() = Comissão esperada</spam></p>
        
        
          <Styles.CorpoTabela>
            <Grid container justifyContent="center" spacing={12}>
              {/* <Button
                variant="contained"
                color="primary"
                className="botao-incluir"
                endIcon={<GroupAddIcon />}
                onClick={() => {
                  navigate(paths.cadastroIndicacaoParceiro);
                }}
              >
                Indicar uma nova empresa
              </Button> */}
            </Grid>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="icone-busca" />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.cabecaAcordeao}
              >
                <Grid container justifyContent="center" spacing={12}>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <Styles.IconeEcom src={images.iconeEcom} />
                    <Typography className={classes.tituloAcordeao}>Empresas Cadastradas</Typography>
                  </Grid>  
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                  
                  </Grid>  
                  <Grid item xs={12} sm={12} xl={3} md={3} className="grid-icone">
                    <SearchIcon className="search-icone" />
                  </Grid>
                </Grid>
                
              </AccordionSummary>
              <AccordionDetails className={classes.corpoAcordeao}>
                <Grid container justifyContent="center" spacing={4}>
                  <Grid item xs={12} sm={12} xl={12} md={12}>
                  <SearchTwoToneIcon className="busca-avancada-icone" />
                  <div className="titulo-busca">
                    Busca Avançada
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="cliente"
                        value={cliente}
                        onChange={handleCliente}
                        startAdornment={<InputAdornment position="start"></InputAdornment>}
                        labelWidth={60}
                        variant="outlined"
                        label="Cliente"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="razao_social"
                        value={razao_social}
                        onChange={handleRazaoSocial}
                        startAdornment={<InputAdornment position="start"></InputAdornment>}
                        labelWidth={60}
                        variant="outlined"
                        label="Razão Social"
                      />

                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                          id="responsavel"
                          value={responsavel}
                          onChange={handleResponsavel}
                          startAdornment={<InputAdornment position="start"></InputAdornment>}
                          labelWidth={60}
                          variant="outlined"
                          label="Responsavel"
                        />
                      
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlilabelFiltroned">
                    <InputLabel className="label-filtro" htmlFor="municipio">Municipio</InputLabel>
                      <Select
                        variant="outlined"
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name} className='menu-item'>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined" className="select-filtro">
                      <InputLabel className="label-filtro2" htmlFor="municipio">UF</InputLabel>
                      <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        value={estado}
                        onChange={handleChangeUF}
                        MenuProps={MenuProps}
                      >
                        {UF.map((ufs) => (
                          <MenuItem key={ufs.sigla} value={ufs.nome} className='menu-item'>
                            {ufs.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined">
                      
                      <TextField
                          id="uc"
                          value={uc}
                          onChange={handleUC}
                          startAdornment={<InputAdornment position="start"></InputAdornment>}
                          labelWidth={60}
                          variant="outlined"
                          label="Cod. UC"
                        />
                      
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                          id="cnpj"
                          value={cnpj}
                          onChange={handleCNPJ}
                          startAdornment={<InputAdornment position="start"></InputAdornment>}
                          labelWidth={60}
                          variant="outlined"
                          label="CNPJ Unidade"
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                          id="dt_indicacao"
                          value={dataIndicacao}
                          onChange={handleDataIndicacao}
                          startAdornment={<InputAdornment position="start"></InputAdornment>}
                          labelWidth={60}
                          variant="outlined"
                          label="Data Indicação"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined" className="select-filtro">
                      <InputLabel className="label-filtro2" htmlFor="municipio">Status Indicação</InputLabel>
                      <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        value={status_indicacao}
                        onChange={handleChangeStatusIndicacao}
                        MenuProps={MenuProps}
                      >
                        {status.map((st) => (
                          <MenuItem key={st} value={st} className='menu-item'>
                            {st}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}>
                    <FormControl fullWidth variant="outlined" className="select-filtro">
                      <InputLabel className="label-filtro2" htmlFor="municipio">Status Negócio</InputLabel>
                      <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        value={status_negocio}
                        onChange={handleChangeStatusNegocio}
                        MenuProps={MenuProps}
                      >
                        {statusnegocio.map((stn) => (
                          <MenuItem key={stn} value={stn} className='menu-item'>
                            {stn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}></Grid>
                  <Grid item xs={12} sm={12} xl={4} md={4}></Grid>
                </Grid>
                <form noValidate autoComplete="off">
                  
                </form>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                <Button 
                  size="small"
                  onClick={() => {
                    limparFiltro();
                  }}
                >
                  Limpar
                </Button>
                <Button 
                  size="small"
                  color="primary"
                  onClick={() => {
                    filtrarParceiros();
                  }}
                >
                  Filtrar
                </Button>
              </AccordionActions>
            </Accordion>

            <TableContainer >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          switch (column.id) {
                            case 'cliente':
                              var valor = row.cliente;
                              break;
                            case 'razao_social':
                              var valor = row.razao_social;
                              break;
                            case 'responsavel':
                              var valor = row.responsavel;
                              break;
                            case 'municipio':
                              var valor = row.municipio;
                              break;
                            case 'estado':
                              var valor = row.estado;
                              break;
                            case 'unidades':
                              var valor = row.unidades;
                              break;
                            case 'codigo_uc':
                              var valor = row.codigo_uc;
                              break;
                            case 'cnpj':
                              var valor = row.cnpj;
                              break;
                            case 'data_indicacao':
                              var valor = row.data_indicacao;
                              break;
                            case 'status_indicacao':
                              var valor = row.status_indicacao;
                              break;
                            case 'status_negocio':
                              var valor = row.status_negocio;
                              break;
                            case 'produto_escolhido':
                              var valor = row.produto_escolhido;
                              break;
                            case 'comissao_esperada':
                              var valor = row.comissao_esperada;
                              break;
                            // case 'data_expiracao':
                            //   var valor = row.data_expiracao;
                            //   break;
                          }
                          
                          return (
                            <TableCell key={column.id} align={column.align} onDoubleClick={()=>{navigate(paths.cadastroIndicacaoParceiro, {state: { empresa_id: row.empresa_id, unidade_consumidora_id: row.unidade_consumidora_id,}})}}>
                              {column.format && typeof value === 'number' ? column.format(valor) : valor}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              labelRowsPerPage = "Itens por página"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Styles.CorpoTabela>
      
          <Modal
            open={openAtention}
            onClose={handleCloseAtention}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableScrollLock={false}
            className="modalatention"
        >
         <>
          <div style={modalStyle} className={classes.paper}>
            <img src={images.iconeatencao} className="iconeatencao"  />
            <div className={classes.paper2}>
              <h3 className="h3atention">Assinaturas pendentes</h3>
              <p>Você tem assinaturas pendentes no acordo de parceria!</p>
              <Button 
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleCloseAtention();
                  }}
                  className="fecharatencao"
              >
                Fechar
              </Button>
            </div>
          </div>
         </>
        </Modal>
      
      {/*  ----            Footer               ------------ */}
      <Footer />
    </Styles.Container>
  );
}