export const prefix = "";

export const paths = {
  login: prefix + "/",
  home: prefix + "/",
  forgetPassword: prefix + "/forgetPassword",
  userRegister: prefix + "/user_register",
  settings: prefix + "/settings",
  reports: prefix + "/reports",
  reportReader: prefix + "/report/:name/:typeId/:id/:subtitle",
  bulletins: prefix + "/bulletins",
  cadastro: prefix + "/cadastro",
  cadastroIndicacaoParceiro: prefix + "/cadastroIndicacaoParceiro",
  novocadastroIndicacaoParceiro: prefix + "/novocadastroIndicacaoParceiro",
  stepcadastroIndicacaoParceiro: prefix + "/stepcadastroIndicacaoParceiro",
  loginsso: prefix + "/login_sso/:tokensso/:chave",
  conteudo: prefix + "/conteudo",
  homeWebApp: prefix + "/homeWebApp",
  indicacoesWebApp: prefix + "/indicacoesWebApp",
  stepcadastroIndicacaoParceiroWebApp: prefix + "/stepcadastroIndicacaoParceiroWebApp",
  editarcadastroIndicacaoParceiroWebApp: prefix + "/editarcadastroIndicacaoParceiroWebApp",
  cadastroIndicacaoParceiroWebApp: prefix + "/cadastroIndicacaoParceiroWebApp",
  fluxocadastroIndicacaoParceiro: prefix + "/fluxocadastroIndicacaoParceiro",
  fluxocadastroIndicacaoParceiro2: prefix + "/fluxocadastroIndicacaoParceiro2",
};
