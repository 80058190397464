import WebBanner from "../assets/images/banner-web-bg.png";
import AppStore from "../assets/images/Download_on_the_App_Store_Badge.png";
import GooglePlay from "../assets/images/Google_Play_Store_badge_EN.png";
import Cellphone from "../assets/images/app-mockup-sombra.png";

import Logo from "../assets/logos//Ecom_RGB__horizontal_roxo-amarelo.svg";
import LogoVertical from "../assets/logos/Ecom_RGB__vertical_tagline_roxo-amarelo.svg";
import LogoHorizontal from "../assets/logos/Ecom_RGB__horizontal_tagline_roxo-amarelo.svg";
import LogoEcom from "../assets/logos/ecomenergia-logo.png";
import LogoEcomFooter from "../assets/logos/logo-footer.png";

import ReportBackground from "../assets/images/bg-relatorios.png";

import Selo1 from "../assets/images/selo_energia_verde.png";
import Selo2 from "../assets/images/i_rec.png";
import Selo3 from "../assets/images/valor_1000.png";

import facebook from "../assets/images/facebook.png";
import instagram from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";


import iconeEcom from "../assets/images/icone-banner.png";
import logonovo from "../assets/images/logo-novo.png";
import falandocelular from "../assets/images/casal-apontando.png";
import botao from "../assets/images/botao.png";
import catavento from "../assets/images/catavento.png";
import formacanto from "../assets/images/formacanto.png";
import iconeatencao from "../assets/images/icone-atencao.png";
import iconefechado from "../assets/images/icone-fechado.png";
import logoescrito from "../assets/images/logo-escrito.png";
import home from "../assets/images/home.png";
import personadd from "../assets/images/person-add.png";
import capturasucesso from "../assets/images/checkmark-circle.png"
import capturafalha from "../assets/images/close-circle.png"
import capturaaguarde from "../assets/images/icone-aguarde.png"


const images = {
  WebBanner,
  AppStore,
  GooglePlay,
  Cellphone,

  Logo,
  LogoVertical,
  LogoHorizontal,
  LogoEcom,
  LogoEcomFooter,

  ReportBackground,

  Selo1,
  Selo2,
  Selo3,

  facebook,
  instagram,
  linkedin,

  iconeEcom,

  logonovo,
  falandocelular,
  botao,
  catavento,
  formacanto,
  iconeatencao,
  iconefechado,

  logoescrito,
  home,
  personadd,
  capturasucesso,
  capturafalha,
  capturaaguarde,
};

export default images;
