import { paths } from "../navigation/navigate";

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    },
    {
      navigationId: 1,
      path: paths.forgetPassword,
      name: "ForgetPassword",
    },
    {
      navigationId: 3,
      path: paths.userRegister,
      name: "UserRegister",
    },
    {
      navigationId: 4,
      path: paths.userRegister,
      name: "UserRegister",
    },
    {
      navigationId: 5,
      path: paths.loginsso,
      name: "LoginSso",
    }
  ],
  logged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Home",
      working: true,
    },
    {
      navigationId: 1,
      path: paths.cadastro,
      name: "Cadastro",
      working: true,
    },
    {
      navigationId: 2,
      path: paths.cadastroIndicacaoParceiro,
      name: "CadastroIndicacaoParceiro",
      working: true,
    },
    {
      navigationId: 8,
      path: paths.novocadastroIndicacaoParceiro,
      name: "NovoCadastroIndicacaoParceiro",
      working: true,
    },
    {
      navigationId: 5,
      path: " ",
      name: "TalkToUs",
      working: true,
    },
    {
      navigationId: 6,
      path: paths.settings,
      name: "Settings",
      working: true,
    },
    {
      navigationId: 3,
      path: paths.stepcadastroIndicacaoParceiro,
      name: "StepCadastroIndicacaoParceiro",
      working: true,
    },
    {
      navigationId: 4,
      path: paths.conteudo,
      name: "Conteudo",
      working: true,
    },
    {
      navigationId: 5,
      path: paths.homeWebApp,
      name: "HomeWebApp",
      working: true,
    },
    {
      navigationId: 6,
      path: paths.indicacoesWebApp,
      name: "IndicacoesWebApp",
      working: true,
    },
    {
      navigationId: 7,
      path: paths.stepcadastroIndicacaoParceiroWebApp,
      name: "StepCadastroIndicacaoParceiroWebApp",
      working: true,
    },
    {
      navigationId: 8,
      path: paths.cadastroIndicacaoParceiroWebApp,
      name: "CadastroIndicacaoParceiroWebApp",
      working: true,
    },
    {
      navigationId: 7,
      path: paths.editarcadastroIndicacaoParceiroWebApp,
      name: "EditarCadastroIndicacaoParceiroWebApp",
      working: true,
    },
    {
      navigationId: 3,
      path: paths.fluxocadastroIndicacaoParceiro,
      name: "FluxoCadastroIndicacaoParceiro",
      working: true,
    },
    {
      navigationId: 4,
      path: paths.fluxocadastroIndicacaoParceiro2,
      name: "FluxoCadastroIndicacaoParceiro2",
      working: true,
    },
  ],
  menu: [
    {
      groupTitle: "Geral",
      pages: [
        {
          navigationId: 0,
          path: paths.login,
          title: "Dashboard",
          working: true,
        },
        {
          navigationId: 1,
          path: paths.cadastro,
          title: "Cadastro",
          working: true,
        },
        {
          navigationId: 2,
          path: paths.stepcadastroIndicacaoParceiro,
          title: "Cadastrar Indicação",
          working: true,
        },
        {
          navigationId: 9,
          path: null,
          title: "Comissionamento",
          working: true,
        },
        {
          navigationId: 4,
          path: paths.conteudo,
          title: "Conteudo",
          working: true,
        },
      ],
    },
    {
      groupTitle: "Sistema",
      pages: [
        {
          navigationId: 4,
          path: paths.settings,
          title: "Configurações",
          working: true,
        },
        {
          navigationId: 5,
          title: "Sair",
          working: true,
        },
      ],
    },
  ],
  menuWebApp: [
    {
      groupTitle: "Geral",
      pages: [
        {
          navigationId: 0,
          path: paths.homeWebApp,
          title: "Home",
          working: true,
        },
        {
          navigationId: 2,
          path: paths.stepcadastroIndicacaoParceiroWebApp,
          title: "Cadastrar cliente",
          working: true,
        }
      ],
    },
    {
      groupTitle: "",
      pages: [
        {
          navigationId: 5,
          title: "Sair",
          working: true,
        },
      ],
    },
  ],
};
export default pagesConfig;
